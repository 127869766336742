import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { X, ChevronDown, User, Plus, Minus } from 'lucide-react';
import axios from 'axios';
import './FilterMenu.css';
import ProgressBar from './ProgressBar';  // Asegúrate de que la ruta sea correcta

// Definimos el z-index del AdvancedFilters
const zIndexAdvancedFilters = 10001; // Asegúrate que este valor sea mayor que el del FilterMenu

const transformRegion = (selectedRegion) => {
  switch (selectedRegion) {
    case 'Metropolitana': return 'RM';
    case 'Arica y Parinacota':
    case 'Tarapacá':
    case 'Antofagasta':
    case 'Atacama':
    case 'Coquimbo': return 'Norte';
    case 'Valparaíso': return 'Quinta';
    case 'O\'Higgins':
    case 'Maule': return 'Centro';
    case 'Ñuble':
    case 'Biobío': return 'Octava';
    case 'Araucanía':
    case 'Los Ríos':
    case 'Los Lagos':
    case 'Aysén':
    case 'Magallanes': return 'Sur';
    default: return undefined;
  }
};

const calculateMinPrice = (income1, income2, ufValue) => {
  const parsedIncome1 = parseFloat((income1 || '').replace(/\./g, '')) || 0;
  const parsedIncome2 = parseFloat((income2 || '').replace(/\./g, '')) || 0;
  const calculatedTotalIncome = parsedIncome1 + parsedIncome2;
  let calculatedMinPrice = Math.round(calculatedTotalIncome * 0.07);
  const maxPrice = (ufValue * 84.3) * 0.07;
  calculatedMinPrice = Math.min(calculatedMinPrice, maxPrice);
  return calculatedMinPrice;
};

const formatIncome = (value) => {
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const FilterMenu = ({ 
  onFilterChange, 
  onMinPriceChange, 
  onSliderPriceChange, 
  onInteraction, 
  onScroll, 
  onSubmit, 
  onShowIncompleteModal 
}) => {
  const zIndexFilterMenu = zIndexAdvancedFilters - 1; // Definimos el z-index del FilterMenu como uno menos que el AdvancedFilters

  const [filters, setFilters] = useState({
    region: undefined,
    income: undefined,
    edad_cotizante1: undefined,
    gender: undefined,
  });
  const [dependents, setDependents] = useState([]);
  const [spouse, setSpouse] = useState(null);
  const [showInsured, setShowInsured] = useState(false);
  const [showGenderModal, setShowGenderModal] = useState(false);
  const [genderInfo, setGenderInfo] = useState({ name: '', phone: '', confirmPhone: '' });
  const [genderErrors, setGenderErrors] = useState({ name: '', phone: '', confirmPhone: '' });
  const [isExpanded, setIsExpanded] = useState(false);
  const insuredRef = useRef(null);
  const genderModalRef = useRef(null);
  const [deviceType, setDeviceType] = useState('mobile');
  const [totalIncome, setTotalIncome] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [precioMinimoCLP, setPrecioMinimoCLP] = useState(0);
  const [precioslider, setPrecioslider] = useState(0);
  const [RegionSeleccionada, setRegionSeleccionada] = useState(undefined);
  const [ufValue, setUfValue] = useState(0);
  const debounceTimerRef = useRef(null);
  const [hasCalculated, setHasCalculated] = useState(false);
  const filterRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [originalTop, setOriginalTop] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [maskActive, setMaskActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const lastScrollTop = useRef(0);
  const stickyThreshold = 50; // Umbral para activar/desactivar el estado sticky
  const [shakeFilter, setShakeFilter] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [shouldSendRequest, setShouldSendRequest] = useState(false);

  const regions = useMemo(() => [
    { value: 'Metropolitana', label: 'Metropolitana' },
    { value: 'Arica y Parinacota', label: 'Arica y Parinacota' },
    { value: 'Tarapacá', label: 'Tarapacá' },
    { value: 'Antofagasta', label: 'Antofagasta' },
    { value: 'Atacama', label: 'Atacama' },
    { value: 'Coquimbo', label: 'Coquimbo' },
    { value: 'Valparaíso', label: 'Valparaíso' },
    { value: 'O\'Higgins', label: 'O\'Higgins' },
    { value: 'Maule', label: 'Maule' },
    { value: 'Ñuble', label: 'Ñuble' },
    { value: 'Biobío', label: 'Biobío' },
    { value: 'Araucanía', label: 'Araucanía' },
    { value: 'Los Ríos', label: 'Los Ríos' },
    { value: 'Los Lagos', label: 'Los Lagos' },
    { value: 'Aysén', label: 'Aysén' },
    { value: 'Magallanes', label: 'Magallanes' }
  ], []);

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= 1100;
      setDeviceType(isDesktop ? 'desktop' : 'mobile');
      if (filterRef.current) {
        setOriginalWidth(filterRef.current.offsetWidth);
        if (window.innerWidth < 1100) {
          filterRef.current.style.position = 'relative';
          filterRef.current.style.zIndex = 'auto'; // Cambiamos el z-index a 'auto' cuando la pantalla es menor a 1100px
          setIsSticky(false);
        } else if (hasCalculated) {
          filterRef.current.style.position = 'static';
          filterRef.current.style.zIndex = '50';
          setIsSticky(false);
        } else {
          filterRef.current.style.zIndex = isSticky ? zIndexFilterMenu : '50';
        }
      }
    };

    setTimeout(() => {
      setIsLoading(false);
      handleResize();
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSticky, hasCalculated]);

  useEffect(() => {
    const filter = filterRef.current;
    if (!filter) return;

    const updateOriginalPosition = () => {
      const rect = filter.getBoundingClientRect();
      setOriginalTop(rect.top + window.pageYOffset);
      setOriginalWidth(rect.width);
    };

    updateOriginalPosition();
    window.addEventListener('resize', updateOriginalPosition);

    const handleScroll = () => {
      if (window.innerWidth < 1100 || hasCalculated) {
        setIsSticky(false);
        filter.style.position = 'relative';
        filter.style.top = '';
        filter.style.left = '';
        filter.style.transform = '';
        filter.style.width = '';
        filter.style.zIndex = 'auto';
        return;
      }

      const currentScrollPos = window.pageYOffset;
      const scrollingDown = currentScrollPos > lastScrollTop.current;

      if (scrollingDown) {
        if (currentScrollPos > originalTop - stickyThreshold && !isSticky) {
          setIsSticky(true);
          filter.style.position = 'fixed';
          filter.style.top = '0';
          filter.style.left = '50%';
          filter.style.transform = 'translateX(-50%)';
          filter.style.width = `${originalWidth}px`;
          filter.style.zIndex = zIndexFilterMenu;
        }
      }

      // Reseteo del sticky al tope del scroll
      if (currentScrollPos <= 0) {
        setIsSticky(false);
        filter.style.position = 'relative';
        filter.style.top = '';
        filter.style.left = '';
        filter.style.transform = '';
        filter.style.width = '';
        filter.style.zIndex = '50';
      }

      lastScrollTop.current = currentScrollPos <= 0 ? 0 : currentScrollPos;

      // Check if the user has scrolled more than 1/4 of the page
      const pageHeight = document.documentElement.scrollHeight;
      if (currentScrollPos > pageHeight / 4 && !isFilterComplete()) {
        onShowIncompleteModal();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateOriginalPosition);
    };
  }, [deviceType, originalTop, originalWidth, isSticky, hasCalculated, onScroll, onShowIncompleteModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (insuredRef.current && !insuredRef.current.contains(event.target)) {
        setShowInsured(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchUfValue = async () => {
      try {
        const response = await axios.get('https://infoplan.cl:4000/api/valor-uf');
        setUfValue(response.data.uf);
      } catch (error) {
        console.error('Error al obtener el valor de la UF:', error);
      }
    };
    fetchUfValue();
  }, []);

  useEffect(() => {
    const handleMaskDeactivate = (event) => {
      if (['INPUT', 'SELECT', 'BUTTON'].includes(event.target.tagName)) {
        setMaskActive(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setMaskActive(false);
      } else {
        setMaskActive(true);
      }
    };

    document.addEventListener('click', handleMaskDeactivate);
    window.addEventListener('resize', handleResize);

    // Llamada inicial para establecer el estado correcto
    handleResize();

    return () => {
      document.removeEventListener('click', handleMaskDeactivate);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = useCallback((field, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [field]: value || undefined }));
    if (field === 'region') {
      setRegionSeleccionada(value || undefined);
    }
    if (deviceType === 'mobile') {
      setIsExpanded(true);
    }
    setIsSticky(false); // Desactiva sticky cuando se interactúa
    if (filterRef.current) {
      filterRef.current.style.position = 'static'; // Asegura que el filtro se vuelva "no sticky" cuando cambien los inputs
    }
    if (onInteraction) {
      onInteraction();
    }
    updateProgressBar();
  }, [deviceType, onInteraction]);

  const calculatePrices = useCallback(() => {
    let validDependents = dependents.filter(d => d.age !== undefined && d.age !== '');
    if (spouse && spouse.age && (!spouse.income || spouse.income === '')) {
      validDependents = [...validDependents, { age: spouse.age }];
    }
    const filterParams = {
      region: transformRegion(filters.region) || undefined,
      edad_cotizante1: filters.edad_cotizante1 || '18',
      edad_cotizante2: (spouse && spouse.age && spouse.income) ? spouse.age : undefined,
      edades_cargas: validDependents.length > 0 ? validDependents.map(d => d.age).join(',') : undefined,
      precio_minimo_clp: precioMinimoCLP > 0 ? precioMinimoCLP.toString() : undefined,
    };
    onFilterChange(filterParams);
  }, [filters, spouse, dependents, precioMinimoCLP, onFilterChange]);

  const handleCalculateClick = async () => {
    if (!validateGenderInfo()) {
      setShowGenderModal(true);
      setShouldSendRequest(true);
      return;
    }

    await sendRequest();
  };

  const sendRequest = async () => {
    const newMinPrice = calculateMinPrice((filters.income || '').replace(/\./g, ''), spouse?.income, ufValue);
    setMinPrice(newMinPrice);
    setPrecioMinimoCLP(newMinPrice);
    setPrecioslider(newMinPrice);
    if (onMinPriceChange) {
      onMinPriceChange(newMinPrice);
    }
    if (onSliderPriceChange) {
      onSliderPriceChange(newMinPrice);
    }
    calculatePrices();

    const cargasValue = dependents.map(d => d.age).join(',');

    const formData = {
      name: genderInfo.name,
      phone: genderInfo.phone,
      rut: '', // Agregar campo RUT si está disponible
      email: '', // Agregar campo email si está disponible
      Ejecutivo: '', // Agregar campo Ejecutivo si está disponible
      precio_total_clp: newMinPrice,
      precio_minimo_clp: newMinPrice,
      cargas: cargasValue || 'Aun no escoge plan',
      edad_cotizante1: filters.edad_cotizante1 || 'Aun no escoge plan',
      edad_cotizante2: spouse?.age || 'Aun no escoge plan',
      region: RegionSeleccionada || 'Aun no escoge plan',
      tipo_de_plan: 'Aun no escoge plan',
      nota: 'Aun no escoge plan',
      codigo_plan: 'Aun no escoge plan'
    };

    const planDetails = {
      isapre: 'Aun no escoge plan',
      plan: 'Aun no escoge plan',
      precio_total_clp: newMinPrice,
      precio_minimo_clp: newMinPrice,
      cargas: cargasValue || 'Aun no escoge plan',
      edad_cotizante1: filters.edad_cotizante1 || 'Aun no escoge plan',
      edad_cotizante2: spouse?.age || 'Aun no escoge plan',
      region: RegionSeleccionada || 'Aun no escoge plan',
      tipo_de_plan: 'Aun no escoge plan',
      nota: 'Aun no escoge plan',
      codigo_plan: 'Aun no escoge plan'
    };

    const requestBody = {
      ...formData,
      planDetails: planDetails
    };

    try {
      // Enviar datos a Pabbly a través del backend
      const response = await axios.post('https://infoplan.cl:4000/api/enviar-a-pabbly', requestBody);
      console.log('Respuesta del servidor:', response.data);
      // Aquí puedes manejar la respuesta, por ejemplo, mostrar un mensaje de éxito
    } catch (error) {
      console.error('Error al enviar los datos a Pabbly:', error);
      // Aquí puedes manejar el error, por ejemplo, mostrar un mensaje de error
    }

    setHasCalculated(true);
    setIsSticky(false);
    if (filterRef.current) {
      filterRef.current.style.position = 'static';
    }
    if (onInteraction) {
      onInteraction();
    }
    setProgressPercentage(100); // Completar la barra al 100% si todo está completo
  };

  const toggleInsured = () => {
    if (!isSticky) {
      setShowInsured(prevShowInsured => !prevShowInsured);
      if (onInteraction) {
        onInteraction();
      }
    }
  };

  const toggleGenderModal = () => {
    if (!isSticky) {
      setShowGenderModal(true);
      if (onInteraction) {
        onInteraction();
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
    if (onInteraction) {
      onInteraction();
    }
  };

  const addDependent = useCallback(() => {
    setDependents(prevDependents => [...prevDependents, { age: undefined }]);
    if (onInteraction) {
      onInteraction();
    }
  }, [onInteraction]);

  const removeDependent = useCallback((indexToRemove) => {
    setDependents(prevDependents => prevDependents.filter((_, index) => index !== indexToRemove));
    if (onInteraction) {
      onInteraction();
    }
  }, [onInteraction]);

  const updateDependent = useCallback((index, field, value) => {
    setDependents(prevDependents => {
      const newDependents = [...prevDependents];
      newDependents[index] = { ...newDependents[index], [field]: value || undefined };
      return newDependents;
    });
    if (onInteraction) {
      onInteraction();
    }
  }, [onInteraction]);

  const toggleSpouse = useCallback(() => {
    setSpouse(prevSpouse => prevSpouse ? null : { age: undefined, income: undefined });
    if (onInteraction) {
      onInteraction();
    }
  }, [onInteraction]);

  const updateSpouse = useCallback((field, value) => {
    setSpouse(prevSpouse => ({ ...prevSpouse, [field]: value || undefined }));
    if (onInteraction) {
      onInteraction();
    }
  }, [onInteraction]);

  const handlePriceChange = useCallback((event) => {
    const newPrice = Number(event.target.value);
    setMinPrice(newPrice);
    setPrecioMinimoCLP(newPrice);
    setPrecioslider(newPrice);
    if (onMinPriceChange) {
      onMinPriceChange(newPrice);
    }
    if (onSliderPriceChange) {
      onSliderPriceChange(newPrice);
    }
    setIsSticky(false); // Cuando se ajusta el slider, se desactiva el sticky
    if (filterRef.current) {
      filterRef.current.style.position = 'static'; // El filtro no debería estar sticky mientras se interactúa con el slider
    }
    if (onInteraction) {
      onInteraction();
    }
  }, [onMinPriceChange, onSliderPriceChange, onInteraction]);

  const handleSliderRelease = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      calculatePrices();
    }, 500);
    setIsSticky(false);
    if (filterRef.current) {
      filterRef.current.style.position = 'static';
    }
    if (onInteraction) {
      onInteraction();
    }
  }, [precioMinimoCLP, calculatePrices, onInteraction]);

  const formatPrice = useCallback((price) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
  }, []);

  const handleGenderInfoChange = (field, value) => {
    setGenderInfo(prevInfo => ({ ...prevInfo, [field]: value }));
    setGenderErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    updateProgressBar();
  };

  const validateGenderInfo = () => {
    const errors = {};
    if (!genderInfo.name) {
      errors.name = 'Campo obligatorio';
    }
    if (!genderInfo.phone) {
      errors.phone = 'Campo obligatorio';
    } else if (genderInfo.phone.length < 8) {
      errors.phone = 'Mínimo 8 caracteres';
    }
    if (!genderInfo.confirmPhone) {
      errors.confirmPhone = 'Campo obligatorio';
    } else if (genderInfo.phone !== genderInfo.confirmPhone) {
      errors.confirmPhone = 'Número de teléfono no coincide';
    }
    setGenderErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isFilterComplete = () => {
    return (
      filters.region &&
      filters.income &&
      filters.edad_cotizante1 &&
      genderInfo.name &&
      genderInfo.phone &&
      genderInfo.phone.length >= 8 &&
      genderInfo.phone === genderInfo.confirmPhone
    );
  };

  const updateProgressBar = useCallback(() => {
    let progress = 0;
    if (filters.region) progress += 20;
    if (filters.income) progress += 20;
    if (filters.edad_cotizante1) progress += 20;
    if (genderInfo.name) progress += 20;
    if (genderInfo.phone && genderInfo.phone === genderInfo.confirmPhone) progress += 20;
    setProgressPercentage(Math.min(progress, 80)); // Máximo 80% hasta que se calcule
  }, [filters, genderInfo]);

  useEffect(() => {
    updateProgressBar();
  }, [filters, genderInfo, updateProgressBar]);

  const handleSaveAndCalculate = () => {
    handleCalculateClick();
    toggleInsured();
  };

  return (
    <div 
      ref={filterRef}
      className={`w-full p-4 rounded-lg shadow-lg transition-shadow duration-300 ${
        isSticky && !hasCalculated ? 'sticky-filter' : 'relative'
      } ${deviceType === 'mobile' ? 'bg-white' : 'bg-[#0063b2]'} ${shakeFilter ? 'shake' : ''}`}
      style={{ 
        zIndex: deviceType === 'mobile' ? 50 : (isSticky ? zIndexFilterMenu : 50),
        visibility: isLoading ? 'hidden' : 'visible'
      }}
      onClick={() => {
        onInteraction();
        if (hasCalculated) {
          setIsSticky(false); // No debe estar sticky después de la interacción
          if (filterRef.current) {
            filterRef.current.style.position = 'relative'; // Asegura que el filtro se mantenga en su lugar
            filterRef.current.style.zIndex = '50';
          }
        }
      }}
    >
      <div className={`${deviceType === 'mobile' && !isExpanded ? 'max-h-20' : ''} overflow-hidden transition-all duration-300 ${deviceType === 'desktop' ? 'h-auto' : ''}`}>
        <div className="flex flex-wrap items-center justify-start gap-2 w-full">
          <select
            value={filters.region || ''}
            onChange={(e) => handleInputChange('region', e.target.value)}
            className="flex-grow flex-shrink-0 min-w-[120px] h-[60px] p-2 border rounded-md bg-gray-100 text-black hover:bg-[#e5e7eb] transition-all duration-200"
          >
            <option value="">Seleccionar región</option>
            {regions.map((region) => (
              <option key={region.value} value={region.value}>{region.label}</option>
            ))}
          </select>
          <div className="relative flex-grow flex-shrink-0 min-w-[150px]">
            <input
              type="text"
              value={filters.income ? `$ ${formatIncome(filters.income)}` : ''}
              onChange={(e) => handleInputChange('income', e.target.value.replace(/\$ /, ''))}
              className="w-full h-[60px] p-2 pl-8 border rounded-md bg-gray-100 text-gray-600 hover:bg-[#e5e7eb] transition-all duration-200"
              placeholder="Ingreso Líquido Mensual"
            />
            <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">$</span>
          </div>
          <input
            type="number"
            value={filters.edad_cotizante1 || ''}
            onChange={(e) => handleInputChange('edad_cotizante1', e.target.value)}
            className={`flex-grow flex-shrink-0 h-[60px] p-2 border rounded-md bg-gray-100 text-black hover:bg-[#e5e7eb] transition-all duration-200 ${
              deviceType === 'desktop' ? 'w-16' : 'min-w-[80px]'
            }`}
            placeholder="Edad"
          />
          <button
            onClick={toggleGenderModal}
            className={`flex-grow flex-shrink-0 min-w-[150px] h-[60px] p-2 border rounded-md bg-gray-100 text-left flex items-center justify-between hover:bg-[#e5e7eb] transition-all duration-200 ${
              !genderInfo.name || !genderInfo.phone || genderInfo.phone.length < 8 || genderInfo.phone !== genderInfo.confirmPhone ? 'border-red-500' : ''
            }`}
          >
            <span className="flex items-center truncate">
              <User size={16} className="mr-1 flex-shrink-0" />
              <span className="truncate">Género</span>
            </span>
            <ChevronDown size={16} className="flex-shrink-0" />
          </button>
          <button
            onClick={toggleInsured}
            className="flex-grow flex-shrink-0 min-w-[150px] h-[60px] p-2 border rounded-md bg-gray-100 text-left flex items-center justify-between hover:bg-[#e5e7eb] transition-all duration-200"
          >
            <span className="flex items-center truncate">
              <User size={16} className="mr-1 flex-shrink-0" />
              <span className="truncate">Modificar asegurados</span>
            </span>
            <ChevronDown size={16} className="flex-shrink-0" />
          </button>
          <button
            onClick={handleCalculateClick}
            className={`flex-grow flex-shrink-0 h-[60px] p-2 rounded-md animated-button font-bold text-lg transition-all duration-200 hover:border-black hover:border-2 ${
              deviceType === 'mobile' ? 'bg-[#0063b2] text-white min-w-[150px]' : 'bg-[#ff0000] text-white min-w-[200px]'
            } ${(!genderInfo.name || !genderInfo.phone || genderInfo.phone.length < 8 || genderInfo.phone !== genderInfo.confirmPhone) ? 'border-red-500' : ''}`}
            style={{
              backgroundImage: 'linear-gradient(45deg, #ff0000 50%, #ff3333 50%)',
              backgroundSize: '250% 100%',
              backgroundPosition: '100%',
              transition: 'all 0.3s ease-out',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundPosition = '0%';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundPosition = '100%';
            }}
          >
            Calcular precios
          </button>
        </div>

        <ProgressBar percentage={progressPercentage} />
      </div>

      {deviceType === 'mobile' && (
        <button
          onClick={toggleExpand}
          className="w-full mt-4 p-2 bg-[#0063b2] text-white rounded-md hover:bg-[#004e8c] transition-all duration-200"
        >
          {isExpanded ? 'Cerrar perfil' : 'Editar perfil'}
        </button>
      )}

      {showInsured && !isSticky && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[99999]">
          <div 
            ref={insuredRef}
            className="bg-white rounded-lg p-6 w-11/12 max-w-2xl max-h-[90vh] overflow-y-auto"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Agregar asegurados</h3>
              <button onClick={toggleInsured} className="text-gray-500 hover:text-gray-700 hover:bg-[#e5e7eb] transition-all duration-200">
                <X size={24} />
              </button>
            </div>
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <span className="text-lg">Pareja/Cónyuge</span>
                <input
                  type="checkbox"
                  checked={spouse !== null}
                  onChange={toggleSpouse}
                  className="w-6 h-6"
                />
              </div>
              {spouse && (
                <div className="mt-4 flex items-center space-x-4">
                  <input
                    type="number"
                    value={spouse.age || ''}
                    onChange={(e) => updateSpouse('age', e.target.value)}
                    className="w-[30%] p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                    placeholder="Edad"
                  />
                  <div className="relative w-[70%]">
                    <input
                      type="text"
                      value={spouse.income ? `$ ${formatIncome(spouse.income)}` : ''}
                      onChange={(e) => updateSpouse('income', e.target.value.replace(/\$ /, ''))}
                      className="w-full p-3 pl-8 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                      placeholder="Ingreso Líquido"
                    />
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">$</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg">Cargas</span>
                <div className="flex items-center">
                  <button 
                    onClick={() => removeDependent(dependents.length - 1)} 
                    className="p-2 bg-gray-200 rounded-full mr-4 hover:bg-[#e5e7eb] transition-all duration-200"
                    disabled={dependents.length === 0}
                  >
                    <Minus size={20} />
                  </button>
                  <span className="text-xl font-semibold mx-4">{dependents.length}</span>
                  <button onClick={addDependent} className="p-2 bg-gray-200 rounded-full ml-4 hover:bg-[#e5e7eb] transition-all duration-200">
                    <Plus size={20} />
                  </button>
                </div>
              </div>
              {dependents.map((dependent, index) => (
                <div key={index} className="flex items-center justify-between mb-4">
                  <span className="text-lg">Carga {index + 1}</span>
                  <div className="flex items-center space-x-4">
                    <input
                      type="number"
                      value={dependent.age || ''}
                      onChange={(e) => updateDependent(index, 'age', e.target.value)}
                      className="w-20 p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                      placeholder="Edad"
                    />
                    <button 
                      onClick={() => removeDependent(index)} 
                      className="p-2 bg-red-200 rounded-full hover:bg-[#e5e7eb] transition-all duration-200"
                    >
                      <Minus size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleSaveAndCalculate}
              className="w-full mt-6 p-3 bg-[#0063b2] text-white rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Guardar y calcular precios
            </button>
          </div>
        </div>
      )}

      {showGenderModal && !isSticky && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[99999]">
          <div 
            ref={genderModalRef}
            className="bg-white rounded-lg p-6 w-11/12 max-w-md"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Crear perfil</h3>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Nombre
              </label>
              <input
                type="text"
                id="name"
                value={genderInfo.name}
                onChange={(e) => handleGenderInfoChange('name', e.target.value)}
                className={`w-full p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200 ${
                  genderErrors.name ? 'border-red-500' : ''
                }`}
                placeholder="Ingrese su nombre"
              />
              {genderErrors.name && <p className="mt-1 text-red-500 text-sm">{genderErrors.name}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                Teléfono
              </label>
              <input
                type="tel"
                id="phone"
                value={genderInfo.phone}
                onChange={(e) => handleGenderInfoChange('phone', e.target.value)}
                className={`w-full p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200 ${
                  genderErrors.phone ? 'border-red-500' : ''
                }`}
                placeholder="Ingrese su teléfono"
              />
              {genderErrors.phone && <p className="mt-1 text-red-500 text-sm">{genderErrors.phone}</p>}
            </div>
            <div className="mb-6">
              <label htmlFor="confirmPhone" className="block text-sm font-medium text-gray-700 mb-1">
                Confirmar Teléfono
              </label>
              <input
                type="tel"
                id="confirmPhone"
                value={genderInfo.confirmPhone}
                onChange={(e) => handleGenderInfoChange('confirmPhone', e.target.value)}
                className={`w-full p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200 ${
                  genderErrors.confirmPhone ? 'border-red-500' : ''
                }`}
                placeholder="Confirme su teléfono"
              />
              {genderErrors.confirmPhone && <p className="mt-1 text-red-500 text-sm">{genderErrors.confirmPhone}</p>}
            </div>
            <button
              onClick={async () => {
                if (validateGenderInfo()) {
                  setShowGenderModal(false);
                  if (shouldSendRequest) {
                    await sendRequest();
                    setShouldSendRequest(false);
                  }
                }
              }}
              className="w-full p-3 bg-[#0063b2] text-white rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Guardar y continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterMenu;